<template>
  <b-modal
    id="ModalPush"
    title="Push-уведомление всем водителям"
    @show="getMessage"
    size="md"
    hide-footer
    no-enforce-focus
    @close="clearData"
    :no-close-on-backdrop="true">
    <b-row>
      <b-col cols="12">
        <b-row class="mb-1">
          <b-col cols="12">
            <label for="">Шаблоны <i class="required">*</i></label>
            <v-select
              :reduce="(option) => option"
              placeholder="Шаблон"
              label="title"
              @input="messageTemplateChange"
              :options="messageTemplates" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <label for="">Сообщение:</label>
            <b-form-textarea rows="5" cols="33" placeholder="Выберите шаблон" v-model="mail.message" disabled />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-button :disabled="submitButtonDisabled" class="mt-2" variant="primary" @click="sendmessage()"
              >Отправить</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    vSelect,
  },
  props: ["phoneNumber"],
  data() {
    return {
      mail: {
        message: "",
        type: "performer",
      },
      messageTemplates: [],
      submitButtonDisabled: false,
    };
  },
  methods: {
    sendmessage() {
      this.submitButtonDisabled = true;
      this.$http
        .post("push-send-all", this.mail)
        .then((res) => {
          this.$bvModal.hide("ModalPush");
          this.clearData();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Сообщение отправлено!`,
            },
          });
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    getMessage() {
      this.$http.get("message-template/filters?filter_type=Push").then((res) => {
        this.messageTemplates = res.data;
      });
    },
    messageTemplateChange(item) {
      if (item) {
        this.mail.message = `${item.title}
${item.description}`;
      } else {
        this.mail.message = "";
      }
    },
    clearData() {
      (this.mail = {
        message: "",
      }),
        (this.messageTemplates = []),
        (this.submitButtonDisabled = false);
    },
  },
  watch: {
    phoneNumber(value) {
      if (value) {
        this.mail.phone = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  color: red;
}
</style>
